body {
    height: 100vh;
    width: 100vw;
    // overflow-y: hidden;
    overflow-x: hidden;
    margin: 0;
    background-color: #f2f2f2;
    overscroll-behavior: none;
}

#mainContainer {
    height: 96vh;
    width: 98vw;
    background-color: black;
    border-radius: 15px;
    overflow: hidden;
    position: absolute;
    right: 1vw;
    top: 2vh;

    canvas {
        background-color: black;
        height: 100%;
        width: 100%;
        filter: blur(30px);
    }
}

#mainText {
    color: white;
    z-index: 3;
    text-align: center;

    //font-size: 15vw;
    span {
        position: absolute;
        width: fit-content;

        &:nth-child(1) {
            font-size: 9vw;
            font-weight: 400;
            font-family: 'Work Sans', sans-serif;
            top: 7%;
            left: 34%;
            z-index: 1;
        }

        &:nth-child(2) {
            font-size: 7vw;
            color: #CC5500;
            font-weight: bold;
            //font-family: 'Inter', sans-serif;
            font-family: 'Work Sans', sans-serif;
            top: 21.5%;
            left: 47%;
            z-index: 2;
            transform: rotate(15deg);
        }

        &:nth-child(3) {
            font-size: 11vw;
            font-weight: bolder;
            font-family: 'Work Sans', sans-serif;
            top: 27%;
            left: 12.2588%;
            z-index: 3;
        }

        &:nth-child(4) {
            font-size: 1vw;
            color: #888;
            font-family: 'Work Sans', sans-serif;
            width: 45%;
            top: 56%;
            left: 27.5%;
        }
    }
}

#basedIn {
    position: absolute;
    color: #f2f2f2;
    top: 86%;
    left: 5%;
    z-index: 2;
    width: fit-content;
    text-align: center;
    line-height: 2;

    #location {
        font-weight: bold;
    }
}

#currentWork {
    position: absolute;
    color: #f2f2f2;
    top: 86%;
    left: 85%;
    z-index: 2;
    width: fit-content;
    text-align: center;
    line-height: 2;
    display: inline-block;

    #current {
        font-weight: bold;
    }
}