.navbar {
    /* overflow: hidden; */
    position: fixed;
    top: 87vh;
    right: 37.5vw;
    width: 25vw;
    z-index: 10;
    border-radius: 15px;
    -webkit-backdrop-filter: blur(100px);
    backdrop-filter: blur(100px);
}

.blur{
    overflow: hidden;
    position: fixed;
    background-color: #333;
    top: 87vh;
    right: 37.5vw;
    height: 6.6vh;
    width: 25vw;
    opacity: 0.8;
    border-radius: 15px;
    filter: blur(3px);
    z-index: 9;
}

.navbar a {
    float: left;
    display: block;
    color: #f2f2f2;
    text-align: center;
    padding: 14px 0px;
    width: 25%;
    text-decoration: none;
    font-size: 16px;
    font-family: 'Work Sans', sans-serif;
    font-weight: 500;
}

.navbar span:hover {
    border-bottom: 3px solid white;
}